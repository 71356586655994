import React from "react";
import axios from "axios";
import { useSegment, paidSocialEvent } from "../../../utils/analytics/index";
import { validateEmail } from "../../../utils/email";
import {
  Name,
  FormName,
  Category,
  PaidSocialEvents,
  UTM,
  platformID
} from "../../../utils/analytics/constants";
import Button, { ButtonVariant } from "../../Button";
import Mail from "../../../images/mail.svg";
import cn from "classnames";
import Loading from "../../Loading";

interface EmbeddedCTAProps {
  title: string;
  textFieldPlaceholder?: string;
  buttonText?: string;
  buttonId?: string;
  successText?: string;
  fullWidth?: boolean;
  setEmbeddedOpen?: any;
}

const EmbeddedCta: React.FC<EmbeddedCTAProps> = props => {
  const {
    title,
    textFieldPlaceholder,
    fullWidth = false,
    successText = "Thanks for signing up! We promise only the best updates and growth rocket fuel coming your way.",
    buttonText = "Get Started",
    setEmbeddedOpen
  } = props;

  const { segment, helpers } = useSegment();
  const [email, setEmail] = React.useState<string>("");
  const [submitted, setSubmitted] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [inputError, setInputError] = React.useState<boolean>(false);

  const inputOnChange = e => {
    setEmail(e?.target?.value);
  };

  const handleSubmit = (e: any) => {
    // 200 ok, however CORS error coming through console after POST
    // If this is blocked this might break...and this is likely the reason why
    e.preventDefault();
    let base = "https://try.perpetua.io/l/804973/2021-10-26/b2yhz";

    axios
      .post(base, encodeURI(`email=${email}`), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
      .finally(() => {
        setLoading(false);
        setSubmitted(true);

        // Send Paid Social event
        paidSocialEvent("fbq", PaidSocialEvents.NewsletterSignup.Facebook);

        setTimeout(() => {
          setEmbeddedOpen(false);
        }, 5000);
      });
    setEmail("");
  };

  const buttonOnClick = e => {
    if (validateEmail(email)) {
      setLoading(true);
      const traits = {
        category: Category.FormInput,
        email: email,
        form_name: FormName.BlogNewsletter,
        campaign: {
          name: localStorage.getItem(UTM.campaign),
          source: localStorage.getItem(UTM.source),
          medium: localStorage.getItem(UTM.medium),
          term: localStorage.getItem(UTM.term),
          content: localStorage.getItem(UTM.content)
        },
        platformID: {
          gclid: localStorage.getItem(platformID.gclid),
          keyword: localStorage.getItem(platformID.keyword),
          campaignid: localStorage.getItem(platformID.campaignid),
          adgroupid: localStorage.getItem(platformID.adgroupid)
        },
        page: {
          path: window?.location.pathname,
          referrer: document.referrer,
          search: window?.location.search,
          title: document.title,
          url: window?.location.href,
          name: Name.Page
        }
      };

      segment.identify(traits.email, {
        ...traits
      });

      helpers.delayTrack(Name.FormCompleted, { ...traits }).then(() => {
        handleSubmit(e);
      });
    } else {
      setInputError(true);
    }
  };

  return (
    <div
      aria-hidden={true}
      className={cn(
        "relative flex flex-col items-center justify-evenly bg-gradient-to-r from-indigo-3-light to-blue-3 p-8 shadow-2xl rounded-2xl md:flex-row",
        { "shadow-none justify-evenly rounded-none sm:rounded-2xl": fullWidth }
      )}
    >
      <div
        className={cn(
          "mr-0 md:mr-6 w-full min-h-[130px] flex items-center md:w-1/2",
          {
            "max-w-auto w-full max-w-[100%] pl-0 md:pl-22": fullWidth
          }
        )}
      >
        <p
          className={cn(
            "text-lg leading-[26px] font-semibold text-indigo-3-dark text-center md:text-left",
            {
              "text-3xl w-full text-center sm:w-auto sm:text-left": fullWidth
            }
          )}
        >
          {title}
        </p>
      </div>
      <div className="md:w-1/2">
        <div
          className={cn("flex flex-col", {
            "flex-col sm:flex-row items-center sm:w-full": fullWidth
          })}
        >
          <input
            className="newsletter-signup-input border border-gray-3 rounded-lg py-3 px-4 w-full text-base"
            placeholder={textFieldPlaceholder}
            value={email}
            onChange={inputOnChange}
          />
          <Button
            className={cn("w-full mt-4 py-3", {
              "w-full sm:w-auto mt-4 sm:mt-0 sm:ml-4 min-w-[150px]": fullWidth
            })}
            variant={ButtonVariant.primary}
            text={buttonText}
            onClick={e => {
              buttonOnClick(e);
            }}
          />
        </div>
        {inputError && !validateEmail(email) && (
          <small className="text-center mt-2 text-red-2-light font-medium">
            Oops! You must enter a valid email address.
          </small>
        )}
      </div>

      {loading && (
        <div className="transition duration-200 absolute w-full h-full left-0 p-8 bg-white flex items-center justify-center rounded-xl">
          <Loading />
        </div>
      )}
      {submitted && (
        <div
          className={cn(
            "absolute w-full h-full left-0 p-8 bg-gradient-to-r from-blue-3 to-purple-3 flex items-center justify-center rounded-xl",
            { "flex-row-reverse gap-8": fullWidth }
          )}
        >
          <div className={"md:mr-8 flex items-center"}>
            <h3
              className={cn(
                "text-xl leading-[26px] font-semibold text-indigo-3-dark",
                { "max-w-[280px]": !fullWidth },
                { "text-3xl max-w-[600px]": fullWidth }
              )}
            >
              {successText}
            </h3>
          </div>
          <Mail />
        </div>
      )}
    </div>
  );
};

export default EmbeddedCta;
