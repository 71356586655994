import { graphql, Link } from "gatsby";
import React, { useEffect, useState } from "react";
import SEO from "../../components/Seo";
import Layout from "../../containers/Layout";
import GradientSection from "../../containers/GradientSection";
import EmbeddedCta from "../../components/Blog/EmbeddedCta";
import { useFlexSearch } from "react-use-flexsearch";
import ResultsNavigation from "../../components/Blog/ResultsNavigation";
import { NavigationBarVariant } from "../../components/NavigationBar/models";
import Section from "../../containers/Section";
import BlogList from "../../components/Blog/BlogList";
import { isBrowser } from "../../utils/browser";
import NavigationBar from "../../components/NavigationBar";
import { marketplaces } from "../../constants/filters";
import cn from "classnames";
import EmbeddedCtaPop from "../../components/Blog/EmbeddedCtaPop";
import LeftArrow from "../../images/left-arrow.svg";
import { useI18next } from "gatsby-plugin-react-i18next";

interface SearchType {
  data: any;
}

const formatResults = results =>
  results?.map(post => {
    const {
      title,
      bodyPreview,
      slug,
      bannerImage,
      timeToRead,
      categories,
      marketplace
    } = post;
    return {
      slug,
      title,
      bodyPreview,
      bannerImage,
      timeToRead,
      categories,
      marketplace
    };
  });

// TODO: pass more info to match Figma Designs
const Search: React.FC<SearchType> = ({
  data: {
    localSearchPages: { index, store },
    allContentfulPublication: { edges }
  }
}) => {
  const { search } = isBrowser() && window?.location;
  const query = new URLSearchParams(search).get("s");
  const [searchQuery, setSearchQuery] = useState(query || "");
  const [activeMarketplace, setActiveMarketplace] = useState<string>("");
  const results = useFlexSearch(searchQuery, index, store);
  const posts = searchQuery ? formatResults(results) : edges;
  const { language } = useI18next();

  return (
    <Layout inlineSwitcher hideNav>
      <SEO
        title={"eCommerce Advertising Blog"}
        description={
          "Perpetua's blog for eCommerce advertising news, insights tips and content for sellers, brands and agencies looking to grow their business."
        }
      />
      <NavigationBar
        setActiveFilter={setActiveMarketplace}
        activeFilter={activeMarketplace}
        variant={NavigationBarVariant.blog}
        filters={marketplaces}
        setSearchQuery={setSearchQuery}
      />
      {/* Give search the ability to pass in local search pages, and return entered search term then on this page we change the query and filter */}
      <div className="hidden md:block">
        <ResultsNavigation
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          setActiveMarketplace={setActiveMarketplace}
          activeMarketplace={activeMarketplace}
        />
      </div>
      <GradientSection className="bg-gradient-to-t from-indigo-3-light min-h-[100vh]">
        <div className="pt-22 md:pt-72">
          <div className="md:hidden pb-8">
            {/* Mobile only */}
            <div className={cn("flex justify-between pt-3 items-center")}>
              <Link
                className="text-black py-4 flex items-center"
                to={language !== "en-US" ? `/${language}/blog/` : "/blog/"}
              >
                <LeftArrow className="mr-3" />
                blog
              </Link>
              <EmbeddedCtaPop />
            </div>
          </div>
          <BlogList
            searchPage
            publications={posts}
            pagination
            activeMarketplace={activeMarketplace}
            setActiveMarketplace={setActiveMarketplace}
            title={searchQuery}
            color=""
          />
        </div>
      </GradientSection>
      <Section mobileWide className="px-0 sm:px-4 sm:py-12">
        <EmbeddedCta
          fullWidth
          buttonText="Sign Up"
          title="Insights to help grow profit"
          textFieldPlaceholder="Email address"
          successText="Thanks for signing up! We promise only the best updates and growth rocket fuel coming your way."
        />
      </Section>
    </Layout>
  );
};

export default Search;

export const pageQuery = graphql`
  query {
    localSearchPages {
      index
      store
    }
    allContentfulPublication(
      sort: { fields: createdAt, order: DESC }
      filter: { node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          id
          slug
          title
          bodyPreview
          timeToRead
          marketplace
          categories {
            name
          }
          bannerImage {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
